$webfontsPath: "fonts/" !default;

// 8 - Black
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan08.eot");
    src: url("#{$webfontsPath}sisan08.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan08.woff2") format("woff2"),
    url("#{$webfontsPath}sisan08.woff") format("woff"),
    url("#{$webfontsPath}sisan08.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

// 38 - Black Italic
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan38.eot");
    src: url("#{$webfontsPath}sisan38.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan38.woff2") format("woff2"),
    url("#{$webfontsPath}sisan38.woff") format("woff"),
    url("#{$webfontsPath}sisan38.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

// 6 - Bold
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan06.eot");
    src: url("#{$webfontsPath}sisan06.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan06.woff2") format("woff2"),
    url("#{$webfontsPath}sisan06.woff") format("woff"),
    url("#{$webfontsPath}sisan06.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

// 36 - Bold Italic
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan36.eot");
    src: url("#{$webfontsPath}sisan36.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan36.woff2") format("woff2"),
    url("#{$webfontsPath}sisan36.woff") format("woff"),
    url("#{$webfontsPath}sisan36.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

// 33 - Italic
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan33.eot");
    src: url("#{$webfontsPath}sisan33.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan33.woff2") format("woff2"),
    url("#{$webfontsPath}sisan33.woff") format("woff"),
    url("#{$webfontsPath}sisan33.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

// 3 - Roman
@font-face {
    font-family: "Siemens Sans";
    font-display: auto;
    src: url("#{$webfontsPath}sisan03.eot");
    src: url("#{$webfontsPath}sisan03.eot#iefix") format("embedded-opentype"),
    url("#{$webfontsPath}sisan03.woff2") format("woff2"),
    url("#{$webfontsPath}sisan03.woff") format("woff"),
    url("#{$webfontsPath}sisan03.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
